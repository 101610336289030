import { TitleBar } from 'components/TitleBar'
import { enGB } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { DateRangePicker, RangeKeyDict } from 'react-date-range'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Dropdown } from 'react-bootstrap'
import ROUTES from 'config/routes'
import subMonths from 'date-fns/subMonths'
import { DeclarationPageResponse } from 'types/DeclarationPageResponse'
import { IClassifier } from 'types/IClassifier'
import { TableFilter as TableHeaderFilter } from 'types/PageResponse'
import { UserPreference } from 'types/UserPreference'
import ClassifierService from '../../services/classifier.service'
import Export from '../Export'
import TransitsTable from './Table'
import { TransitsAside } from './TransitsAside'
import NotesModal from '../Declaration/NotesModal'
import TableFilter from '../TableFilter'
import UserPreferenceService from '../TableFilter/services/user-preference.service'
import { sortBySortOrder } from '../../helpers'

function Transits(props: { checkAside(input: boolean): void }) {
  const { checkAside } = props
  const [asideDeclarationId, setAsideDeclarationId] = useState<number | null>(null)
  const [originalRow, setOriginalRow] = useState<DeclarationPageResponse>()
  const [isAsideOpen, toggleAside] = useState<boolean>(false)
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false)
  const [isExportModalVisible, toggleExportModalVisibility] = useState<boolean>(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)
  const [commodityCodeFilter, setCommodityCodeFilter] = useState<string>('')
  const history = useNavigate()
  const { t } = useTranslation()

  const [userPreferences, setUserPreferences] = useState<Array<UserPreference>>([])

  useEffect(() => {
    UserPreferenceService.getPreferences().then((preferences) => {
      if (preferences) {
        setUserPreferences(preferences.sort(sortBySortOrder))
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }, [])

  const toggleNotesModal = (input: boolean, declarationId: number, tableRow?: DeclarationPageResponse) => {
    setAsideDeclarationId(declarationId || null)

    if (tableRow) {
      setOriginalRow(tableRow)
    }

    return setIsNotesOpen(input)
  }

  const changeAside = (input: boolean, declarationId?: number, tableRow?: DeclarationPageResponse) => {
    setAsideDeclarationId(declarationId || null)
    if (tableRow) {
      setOriginalRow(tableRow)
    }
    checkAside(input)
    return toggleAside(input)
  }

  const [isDateSelectOpen, toggleDateSelect] = useState<boolean>(false)
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [startDate, setStartDate] = useState<Date>(() => subMonths(endDate, 3))
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [filters, setFilters] = useState<Array<TableHeaderFilter>>([])

  const [countries, setCountries] = useState<Array<IClassifier>>([])
  const [customsOffices, setCustomsOffices] = useState<Array<IClassifier>>([])
  const [packageTypes, setPackageTypes] = useState<Array<IClassifier>>([])
  const [documentTypes, setDocumentTypes] = useState<Array<IClassifier>>([])
  const [previousDocumentTypes, setPreviousDocumentTypes] = useState<Array<IClassifier>>([])
  const [procedureTypes, setProcedureTypes] = useState<Array<IClassifier>>([])

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  }

  useEffect(() => {
    checkAside(isAsideOpen)
    ClassifierService.getClassifier('CUSTOMS_OFFICES')
      .then((classifiers: IClassifier[]) => {
        setCustomsOffices(classifiers)
      })
    ClassifierService.getClassifier('COUNTRIES')
      .then((classifiers: IClassifier[]) => {
        setCountries(classifiers)
      })
    ClassifierService.getClassifier('PACKAGE_TYPES')
      .then((classifiers: IClassifier[]) => {
        setPackageTypes(classifiers)
      })
    ClassifierService.getClassifier('DOCUMENT_TYPES')
      .then((classifiers: IClassifier[]) => {
        setDocumentTypes(classifiers)
      })
    ClassifierService.getClassifier('PREVIOUS_DOCUMENT_TYPES')
      .then((classifiers: IClassifier[]) => {
        setPreviousDocumentTypes(classifiers)
      })
    ClassifierService.getClassifier('PROCEDURE_TYPES')
      .then((classifiers: IClassifier[]) => {
        setProcedureTypes(classifiers)
      })
  }, [])

  const handleSelect = ({ selection }: RangeKeyDict) => {
    if (selection && selection.startDate) {
      setStartDate(selection.startDate)
    }

    if (selection && selection.endDate) {
      setEndDate(selection?.endDate)
    }
  }

  function closeOverlay() {
    if (isAsideOpen) {
      toggleAside(false)
    } else {
      toggleDateSelect(false)
    }
    checkAside(false)
  }

  const editDeclaration = (isPhase5: boolean) => {
    checkAside(false)
    if (asideDeclarationId === null) { return }
    if (isPhase5) {
      history(`/transit-operation/${asideDeclarationId}`)
    } else {
      history(`/transit/${asideDeclarationId}`)
    }
  }
  const clearFilters = () => {
    setStatusFilter('')
    setFilters([])
    localStorage.setItem('tardekSearch', JSON.stringify([]))
  }

  const selectedDateString = `${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()} - ${
    endDate.getDate()}.${endDate.getMonth() + 1}.${endDate.getFullYear()}`

  return (
    <>
      <TitleBar title={t('buttons.transits')} isMobileThin>
        <div
          className="d-flex justify-content-between align-items-center ps-3 w-100"
        >
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-light btn-lg d-flex align-items-center rounded-lg datePicker"
              onClick={() => toggleDateSelect(!isDateSelectOpen)}
            >
              <i className="fal fa-calendar-alt fa-md me-sm-2" />
              <span className="d-none d-sm-block">
                {selectedDateString}
              </span>
            </button>
            <Button
              className="mx-2"
              variant="outline-dark"
              size="sm"
              onClick={clearFilters}
            >
              <span>
                {t('buttons.filter', { context: 'clear' })}
              </span>
            </Button>
            <input
              className="form-control"
              type="text"
              maxLength={10}
              placeholder={t('goodsItem.hsCode')}
              value={commodityCodeFilter}
              onChange={(event) => setCommodityCodeFilter(event.target.value ?? '')}
            />
          </div>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-lg me-4"
              onClick={(() => { setIsFilterModalOpen(true) })}
            >
              <i className="far fa-filter me-2" />
              <span>
                {t('buttons.filter')}
              </span>
            </button>
            <div className="d-md-block d-xl-none d-lg-none mt-2">
              <Dropdown>
                <Dropdown.Toggle variant="link" className="p-0 hide-caret" id="dropdown-basic">
                  <i className="fas fa-ellipsis-h fa-md" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow-sm">
                  <Dropdown.Item onClick={() => toggleExportModalVisibility(true)}>
                    <i className="far fa-file-excel me-2" />
                    <span>{t('buttons.export')}</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <button
              type="button"
              className="btn btn-outline-dark btn-lg me-4 d-none d-lg-block"
              onClick={(() => { toggleExportModalVisibility(true) })}
            >
              <i className="far fa-file-excel me-2" />
              <span>
                {t('buttons.export')}
              </span>
            </button>
          </div>
        </div>
      </TitleBar>
      <TransitsTable
        modalAction={toggleNotesModal}
        asideAction={changeAside}
        customsOffices={customsOffices}
        startDate={startDate}
        endDate={endDate}
        tablePreferences={userPreferences}
        commodityCodeFilter={commodityCodeFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        filters={filters}
        setFilters={setFilters}
      />
      <div
        className={`${isAsideOpen || isDateSelectOpen ? 'active' : ''} overlay`}
        role="presentation"
        onClick={closeOverlay}
      />
      {asideDeclarationId && (
        <NotesModal
          originalRow={originalRow}
          setIsNotesModalOpen={setIsNotesOpen}
          isNotesModalOpen={isNotesOpen}
          declarationId={asideDeclarationId}
        />
      )}
      {
        asideDeclarationId
        && (
          <TransitsAside
            isViewMode={false}
            isOpen={isAsideOpen}
            asideAction={changeAside}
            editDeclaration={editDeclaration}
            asideDeclarationId={asideDeclarationId}
            originalRow={originalRow}
            customsOffices={customsOffices}
            countries={countries}
            packageTypes={packageTypes}
            documentTypes={documentTypes}
            previousDocumentTypes={previousDocumentTypes}
            procedureTypes={procedureTypes}
          />
        )
      }
      <div className="transits">
        <DateRangePicker
          locale={enGB}
          className={(isDateSelectOpen) ? '' : 'd-none'}
          ranges={[selectionRange]}
          onChange={handleSelect}
        />
      </div>
      <Export
        modalVisibilityState={isExportModalVisible}
        setModalVisibility={toggleExportModalVisibility}
        isPhaseFive={false}
      />
      <TableFilter
        isTableFilterModalOpen={isFilterModalOpen}
        setIsTableFilterModalOpen={setIsFilterModalOpen}
        setUserPreferences={setUserPreferences}
        userPreferences={userPreferences}
      />
    </>
  )
}

export default Transits
